/* eslint-disable jsx-a11y/anchor-has-content */
import { useEffect, useMemo } from "react";
import { GdkNavigation } from "@gdk/navigation";
import { useAuthContext } from "../contexts/AuthContextProvider";

export const Navbar = () => {
    const authContext = useAuthContext();

    const userName = authContext.getUserName();    
    const userType = authContext.getUserType();

    useEffect(() => {
        new GdkNavigation({
            content: "#primary-navigation"
        });        
    }, []);

    return useMemo(() => {        
        return (<>
            <a className="skip-to-content" href="#wrapper">Skip to main content</a>
            <header id="primary-header" role="banner">
                <div className="header-logo"><a className="icon-geico" aria-label="GEICO Home" href="/"></a></div>
                <div className="header-links">
                    <ul>
                        {userType === "Agent" && <li><div className="user-name-lable">Welcome {userName}</div></li>}

                        <li><a data-side-panel-trigger="menu" className="hamburger-menu" href="/" aria-expanded="false"><span></span><span></span><span></span><span></span><span className="header-link">Menu</span></a></li>
                    </ul>
                </div>
            </header>
            <nav id="primary-navigation" role="navigation">
                <div className="nav-background"></div>
                <div className="panel-wrapper" data-side-panel="menu">
                    <div className="nav-menu">
                        <ul className="nav-primary-tier nav-items">
                            <li><a className="nav-additional-links" data-nav-items-trigger="privacy" rel="noreferrer" target="_blank" href="https://media.geico.com/legal/privacy_policy.htm?">Privacy</a></li>
                            <li><a className="nav-additional-links" data-nav-items-trigger="Personal" rel="noreferrer" target="_blank" href="https://www.geico.com/privacy/">Personal Data Request & Notice</a></li>
                            <li><a className="nav-additional-links" data-nav-items-trigger="legal" rel="noreferrer" target="_blank" href="https://www.geico.com/legal/">Legal & Security</a></li>
                            <li><a className="nav-additional-links" data-nav-items-trigger="contactUs" rel="noreferrer" target="_blank" href="https://www.geico.com/contact-us/">Contact Us</a></li>                      
                            <li><a className="nav-additional-links" data-nav-items-trigger="stratOver" rel="noreferrer" target="_blank" href="https://www.geico.com/">Start Over</a></li>
                        </ul>
                    </div>
                </div>
            </nav>
        </>
        );
    }, [userName, userType]);
};