import { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import { Route, Routes, Navigate, useNavigate, useLocation } from "react-router-dom";
import { useApi } from "./api";
import { BASE_APP_PAth } from "./api/launchpad-api";
import { CommercialQuote, SaveQuoteRequest } from "./api/types/quote-request";
import { Navbar } from "./components/navbar";
import { CommercialProduct } from "./config/types/product-config-settings";
import { GeicoPageNames, IGeicoDtm, useAdobeAnalytics } from "./contexts/AdobeAnalyticsContext";
import { useClientConfigs } from "./contexts/ClientConfigContext";
import store, { RootState } from "./store";
import { clearCache, updateQuote, InitQuoteCache, updateQuoteResponse } from "./store/quote/action";
import { ProtectedRouter } from "./util/ProtectedRouter";
import { UnAuthorised } from "./pages/un-authroised/UnAuthorised";
import { ZipSelection } from "./pages/zip-selection/zip-selection";
import { ProductSelection } from "./pages/product-selection/product-selection";
import { useAutoRedirectContext } from "./contexts/AutoRedirectContext";
import { useAuthContext } from "./contexts/AuthContextProvider";
import { usePageLoadingContext } from "./contexts/PageLoadingContext";
import { BusinessKeyword } from "./pages/business-keyword/business-keyword";
import { BusinessKeywordAgent } from "./pages/business-keyword/business-keyword-agent";

export const App = () => {
    const { geicoDtm, sendToAdobe, setGeicoDtm } = useAdobeAnalytics();
    const clientConnfig = useClientConfigs();
    const { hideLoader } = usePageLoadingContext();
    const quote = useSelector((state: RootState) => state.quote.quote);
    const redirectContext = useAutoRedirectContext();
    const navigate = useNavigate();
    const location = useLocation();
    const api = useApi();
    const authContext = useAuthContext();
    const [showUI, setShowUI] = useState(false);
    const isPopstateListenerAdded = useRef(false); // Ref to store the flag


    const saveUserTypeInRedux = () => {
        const employeeId = authContext.getEmployeeId();
        const userType = authContext.getUserType();
        var saveQuote = new SaveQuoteRequest();
        saveQuote.UserType = userType;
        saveQuote.UserId = employeeId;
        store.dispatch(InitQuoteCache(saveQuote));
    }

    const startNewSession = (selectedZip: string | null, selectedStateCode: string | null, zipVerified: boolean, selectedProduct: CommercialProduct | undefined): void => {
        const newQuote = new CommercialQuote();
        newQuote.Zip = selectedZip && selectedZip.length <= 5 ? selectedZip : quote.data.Zip;
        newQuote.StateCode = selectedStateCode && selectedStateCode.length === 2 ? selectedStateCode : quote.data.StateCode;

        newQuote.Soa = quote.data.Soa === null || quote.data.Soa === "" ? redirectContext.soa : quote.data.Soa;
        newQuote.LaunchpadId = quote.data.LaunchpadId;
        newQuote.SourceCorrelationId = quote.data.SourceCorrelationId === null || quote.data.SourceCorrelationId === ""
            ? redirectContext.sourceCorrelationId : quote.data.SourceCorrelationId;

        newQuote.SelectedProducts = selectedProduct === undefined ? quote.data.SelectedProducts :
            [
                {
                    LobCode: selectedProduct.lobCode,
                    LobName: selectedProduct.lobName,
                    SspCode: selectedProduct.sspCode,
                    AdobeDtmName: selectedProduct.adobeDtmName
                }];
        newQuote.BusinessKeyword = quote.data.BusinessKeyword;

        saveUserTypeInRedux();
        store.dispatch(updateQuote({ ...newQuote }));
        setShowUI(true);
        hideLoader();
    }

    const autoSaveAndRedirect = () => {

        var selectedProducts = redirectContext.product == undefined ? [] :
            [
                {
                    LobCode: redirectContext.product.lobCode,
                    LobName: redirectContext.product.lobName,
                    SspCode: redirectContext.product.sspCode,
                    AdobeDtmName: redirectContext.product.adobeDtmName
                }];

        let saveAndAutoRedirect = true;
        if (["BOP", "GL"].find(p => p.toLocaleLowerCase() === redirectContext?.product?.lobCode.toLocaleLowerCase())) {
            saveAndAutoRedirect = false;
        }

        api.commercialAgentApi.saveQuote({
            ...quote.data,
            Zip: redirectContext.zipCode,
            StateCode: redirectContext.stateCode,
            SelectedProducts: selectedProducts,
            Soa: redirectContext.soa,
            SourceCorrelationId: redirectContext.sourceCorrelationId
        }, saveAndAutoRedirect).then((quoteResponse) => {
            if (quoteResponse.type === "SAVE_QUOTE_RESPONSE") {
                let redirectURL = quoteResponse.data.redirectUrl;

                if (saveAndAutoRedirect && redirectURL && redirectURL.length > 0) {
                    store.dispatch(clearCache());

                    // Adobe Geico DTM Track call
                    // Init adobe Dtm
                    const adobeDtm: IGeicoDtm = {
                        ...geicoDtm,
                        zip: quote.data.Zip,
                        state: quote.data.StateCode,
                        product: redirectContext.product?.adobeDtmName
                    };
                    setGeicoDtm(adobeDtm);
                    sendToAdobe();
                    // End of Adobe DTM
                    window.location.replace(redirectURL);
                }
                else {

                    store.dispatch(updateQuote({
                        ...quote.data,
                        LaunchpadId: quoteResponse.data.launchpadId,
                        Soa: redirectContext.soa,
                        SourceCorrelationId: redirectContext.sourceCorrelationId,
                        StateCode: redirectContext.stateCode,
                        Zip: redirectContext.zipCode,
                        SelectedProducts: selectedProducts
                    }));
                    setShowUI(true);
                }
            }
        }, (error) => {
            console.error(error);
            setShowUI(true);
        });
    }

    useEffect(() => {
        setGeicoDtm({ ...geicoDtm, viewName: GeicoPageNames.Launchpad });

    }, []);

    useEffect(() => {
        if (!isPopstateListenerAdded.current) {

            // Handle browser back button 
            const handleBackButton = (event: PopStateEvent) => {
                if (!quote?.data?.Zip
                    || !quote.data.StateCode
                    || quote.data.Zip?.length !== 5
                    || quote.data.StateCode?.length !== 2
                ) {
                    navigate(BASE_APP_PAth + "/zip-selection", { replace: true });
                    return;
                }
                if (!quote.data.SelectedProducts || quote.data.SelectedProducts.length < 1
                ) {
                    navigate(BASE_APP_PAth + "/product-selection", { replace: true });
                }
            };
            window.addEventListener('popstate', handleBackButton);
            isPopstateListenerAdded.current = true; // Set the flag to true
            return () => {
                window.removeEventListener('popstate', handleBackButton);
                isPopstateListenerAdded.current = false; // Reset the flag
            };
        }
    }, []);

    useEffect(() => {
        if (clientConnfig.configLoaded) {
            if (redirectContext.verificationDone) {
                if (redirectContext.isAutoRedirect && clientConnfig.isAutoRedirectEnabled) {
                    autoSaveAndRedirect();
                }
                else {
                    startNewSession(redirectContext.zipCode, redirectContext.stateCode, true, redirectContext.product);
                }
            }
        }
    }, [clientConnfig.configLoaded, redirectContext]);

    // Set path when show UI
    useEffect(() => {
        if (showUI) {
            if (redirectContext.verificationDone
                && redirectContext.zipCode?.length === 5
                && redirectContext.stateCode?.length === 2
                && redirectContext.product
                && ["BOP", "GL"].find(p => p.toLocaleLowerCase() === redirectContext.product?.lobCode.toLocaleLowerCase())
            ) {
                navigate(BASE_APP_PAth + "/business-keyword", { replace: true });
                return;
            }

            if (redirectContext.verificationDone
                && redirectContext.zipCode?.length === 5
                && redirectContext.stateCode?.length === 2
            ) {
                navigate(BASE_APP_PAth + "/product-selection", { replace: true });
                return;
            }
            if (redirectContext.verificationDone
                || !redirectContext.zipCode
                || !redirectContext.stateCode
                || redirectContext.zipCode?.length !== 5
                || redirectContext.stateCode?.length !== 2
            ) {
                navigate(BASE_APP_PAth + "/zip-selection", { replace: true });
                return;
            }

            if (!quote.data
                || !quote.data.Zip
                || !quote.data.StateCode
                || quote.data.Zip?.length !== 5
                || quote.data.StateCode?.length !== 2
            ) {
                navigate(BASE_APP_PAth + "/zip-selection", { replace: true });
                return;
            }
        }
    }, [showUI]);

    // First time loading
    if (!clientConnfig.configLoaded || !showUI)
        return (<></>);

    return (
        <div className="main-wrapper">
            <Navbar />
            {/* <div id="title-bar" className="fixed top-0  left-0 right-0 py-0 bg-white shadow-md">Geico Commercial Service </div> */}
            <main id="wrapper" role="main" className="bg-color--cloudy">
                {
                    clientConnfig.userType === "Agent"
                        ? <div id="title-bar">Commercial Launchpad (Agent)</div>
                        : null
                }
                {
                    <Routes>
                        <Route element={<ProtectedRouter roles={["commercial_launchpad_agent", "GFR"]} />}>
                            <Route path="*" element={<Navigate replace to={{
                                pathname: "business-insurance",
                                search: window.location.search.toLowerCase()
                            }} />} />

                            <Route path="business-insurance" element={<></>} />
                            <Route path="business-insurance/zip-selection" element={<ZipSelection />} />
                            <Route path="business-insurance/product-selection" element={<ProductSelection />} />
                            {/* <Route path="business-insurance/business-keyword" element={<BusinessKeyword />} />   */}
                            <Route path="business-insurance/business-keyword"
                                element={
                                    clientConnfig.userType === "Agent"
                                        && clientConnfig.isMultiplePartnerRecommendationEnabled
                                        ? <BusinessKeywordAgent /> : <BusinessKeyword />
                                }
                            />
                        </Route>
                        <Route path="/un-authorised" element={<UnAuthorised />}></Route>
                    </Routes>
                }
            </main>
        </div>);
};
