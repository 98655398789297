export const CLIENT_CONFIG_RESPONSE = "CLIENT_CONFIG_RESPONSE"

export interface ClientConfigData {
    adobeAnalyticsUri: string;    
    configLoaded: boolean;
    geicoAppUrl: string;
    userType: string,
    adClientId: string,   
    isAutoRedirectEnabled: boolean;
    isMultiplePartnerRecommendationEnabled: boolean;
    adInstance: string;
    graphUrl: string
}

export const defaultConfig: ClientConfigData = {
    adobeAnalyticsUri: "",   
    configLoaded: false,
    geicoAppUrl: "https://www.geico.com",    
    isAutoRedirectEnabled: false,
    isMultiplePartnerRecommendationEnabled: false,
    userType: "",
    adClientId: "",
    adInstance: "",
    graphUrl: ""
}

export interface ClientConfigResponse {
    type: typeof CLIENT_CONFIG_RESPONSE;
    data: ClientConfigData
}
